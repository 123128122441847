<style lang="css">
    @import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
    @import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

    :root {
        --ag-header-background-color: #F0F3F6;
        --ag-header-foreground-color: #646E78;
        --ag-row-border-color: #E6EBF0;
        --ag-data-color: #1E2832;
        --ag-even-row-background-color: #FFFFFF;
        --ag-odd-row-background-color: #FFFFFF;
        --ag-row-hover-color: #F7F9FB;
        --ag-border-color: #F0F3F6;
    }

    .ag-theme-alpine {
        box-shadow: 0 10px 15px -6px rgb(0 20 40 / 35%) !important;
        font-family: montserrat, sans-serif !important;
    }

    .ag-header-cell-text {
        font-size: 12px !important;
        font-weight: 400 !important;
        text-transform: uppercase !important;
    }

    .ag-root-wrapper {
        border: none !important;
        overflow: visible !important;
    }

    .ag-header {
        border-bottom: none !important;
    }

    .ag-row {
        font-weight: 500 !important;
        font-size: 12px !important;
    }

    .ag-row .ag-cell {
        display: flex;
        align-items: center;
    }

    .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-print .ag-center-cols-container {
        min-height: unset;
    }

    .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
        line-height: unset;
    }

    .ag-theme-alpine .ag-cell span, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group span {
        overflow-y: auto;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ag-watermark {
        display: none !important;
    }

    .ag-watermark {
        display: none !important;
    }

    .ag-center-cols-viewport {
        height: 100% !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
    }

    .ag-cell span::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #E9EEF3;
    }

    .ag-cell span::-webkit-scrollbar-thumb {
        background: #D9E2EC;
    }

    .ag-menu {
        min-height: 5vh !important;
    }

    @media (max-width: 1024px) {
        .ag-header-cell-text {
            font-size: 10px !important;
            font-weight: 400 !important;
        }
        .ag-row {
            font-weight: 400 !important;
            font-size: 10px !important;
        }
    }

    @media (max-width: 1400px) {
        .ag-header-cell-text {
            font-size: 11px !important;
            font-weight: 500 !important;
        }
        .ag-row {
            font-weight: 500 !important;
            font-size: 11px !important;
        }
    }
</style>
<style scoped>
    .aggrid-container {
        width: 100%;
    }

    .aggrid-filter {
        margin-bottom: 25px;
        display: flex;
        justify-content: flex-end;
    }

    .aggrid-title {
        background-color: #0d3b70;
        color: white;
        margin-top: -30px;
        height: 30px;
        width: fit-content;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
    }

    .aggrid-title:after {
        position: absolute;
        right: -15px;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 15px solid #0d3b70;
    }
</style>

<template>
    <div class="aggrid-container" :style="cssProps">
        <div class="aggrid-filter">
            <slot name="filter"></slot>
        </div>
        <div v-if="title !== null" class="aggrid-title">{{ title }}</div>
        <ag-grid-vue style="width: 100%; max-height: calc(85vh - var(--aggrid-height-substract));" :style="aggridCssProps" class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :frameworkComponents="frameworkComponents"
            :gridOptions="gridMergedOptions"
            @grid-ready="onGridReady"
            @row-clicked="onRowClicked"
            :context="context"
            :allowContextMenuWithControlKey="true"
            :getContextMenuItems="getContextMenuItems"
            @column-visible="onColumnVisible"
            @sort-changed="onSortChanged"
            @column-resized="onColumnResized"
        >
        </ag-grid-vue>
    </div>
</template>

<script>
  import {AgGridVue} from "ag-grid-vue3";
  import axios from 'axios';
  import $ from 'jquery';

  let that = null;

  export default {
    name: 'Aggrid',
    props: {
        apiRoute: {
            type: String,
            default: ''
        },
        apiParams: {
            type: Object,
            default: null
        },
        columnDefs: {
            type: Array,
            default: null
        },
        rowData: {
            type: Array,
            default: null
        },
        frameworkComponents: {
            type: Object,
            default: null
        },
        gridOptions: {
            type: Object,
            default: null
        },
        context: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        aggridHeightSubstract: {
            type: String,
            default: '30px'
        },
        exportName: {
            type: String,
            default: ''
        },
        cellRendererFunctions: {
            type: Object,
            default: new Map()
        },
        valueFormatterFunctions: {
            type: Object,
            default: new Map()
        },
    },
    components: {
        AgGridVue
    },
    watch: {
        apiParams: {
            handler() {
                this.aggridApiParams = this.apiParams;
            },
            immediate: true,
            deep: true
        },
        aggridApiParams: {
            handler() {
                if (this.gridReady) {
                    if (JSON.stringify(this.aggridApiParams) != JSON.stringify(this.previousFilter)) {
                        this.previousFilter !== null && this.gridApi.refreshInfiniteCache();
                        this.previousFilter = JSON.parse(JSON.stringify(this.aggridApiParams));
                    }
                }
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            paginationParams: {
                page: 1,
                numberItems: this.numberItems
            },
            gridMergedOptions: {},
            aggridApiParams: null,
            loaders: [],
            aggridCssProps: {
                height: '100px'
            },
            params: null,
            previousFilter: null
        }
    },
    computed: {
        cssProps() {
            return {
                '--aggrid-height-substract': this.aggridHeightSubstract,
            }
        }
    },
    beforeMount() {
        this.gridMergedOptions = {
            paginationPageSize: this.numberItems,
            cacheBlockSize: this.numberItems,
            maxBlocksInCache: 1,
            suppressCellSelection: true,
            suppressMovableColumns: true,
            suppressHorizontalScroll: true,
            suppressPropertyNamesCheck: true,
            getRowNodeId: function (data) {
                return data.id;
            },
            defaultColDef: {
                filter: 'agTextColumnFilter', // Surcharger par agNumberColumnFilter ou agDateColumnFilter dans columnDefs si besoin
                resizable: true
            },
            animateRows: true,
            rowHeight: 70,
            rowBuffer: 0,
            rowSelection: 'multiple',
            rowModelType: 'infinite',
            cacheBlockSize: 20,
            cacheOverflowSize: 1,
            maxConcurrentDatasourceRequests: 3,
            infiniteInitialRowCount: 20,
            maxBlocksInCache: 20,
            localeText: {
                loadingOoo: 'Chargement...',
                noRowsToShow: 'Aucune ligne à afficher',
                to: 'à',
                of: 'sur',
                more: '...',
                pinColumn: 'Épingler',
                pinLeft: 'Épingler à gauche',
                pinRight: 'Épingler à droite',
                noPin: 'Ne pas épingler',
                autosizeThiscolumn: 'Redimensionner cette colonne',
                autosizeAllColumns: 'Redimensionner toutes les colonnes',
                resetColumns: 'Réinitialiser colonnes',
                filterOoo: 'Filtre...',
                contains: 'Contient',
                notContains: 'Ne contient pas',
                startsWith: 'Commence par',
                endsWith: 'Termine par',
                equals: 'Égal',
                notEqual: 'Non égal',
                searchOoo: 'Rechercher...',
                andCondition: 'ET',
                orCondition: 'OU',
                lessThan: 'Moins que',
                lessThanOrEqual: 'Moins que ou égal',
                greaterThan: 'Plus que',
                greaterThanOrEqual: 'Plus que ou égal',
                inRange: 'Compris entre',
                inRangeStart: 'De',
                inRangeEnd: 'à'
            },
            onFilterChanged: function() {
                const filters = this.api.getFilterModel();
                let filtersObject = [];

                for (let key of Object.keys(filters)) {
                    let filterParam = this.api.getFilterInstance(key).textFilterParams ?? this.api.getFilterInstance(key).numberFilterParams ?? this.api.getFilterInstance(key).dateFilterParams;

                    let field = filterParam.colDef.field ?? filterParam.colDef.cellRendererParams?.field;

                    if (field != null || filterParam?.colDef?.customFilter != null) {
                        filtersObject.push({
                            field: filterParam?.colDef?.customFilter ?? field,
                            operator: filters[key].operator,
                            conditions: filters[key].operator ? [filters[key].condition1, filters[key].condition2] : [filters[key]]
                        });
                    }
                }

                if (that.aggridApiParams == null) that.aggridApiParams = {};
                that.aggridApiParams = Object.assign(that.aggridApiParams, {filters: JSON.stringify(filtersObject)});
            }
        }

        if (this.gridOptions !== null) {
            this.gridMergedOptions = {
                ...this.gridMergedOptions,
                ...this.gridOptions
            };
        }

        this.gridReady = false;
    },
    mounted() {
        this.emitter.on('ag-grid-reload', this.reload);
        that = this;
    },
    unmounted() {
        this.emitter.off('ag-grid-reload', this.reload);
    },
    methods: {
        onGridReady(params) {
            this.gridReady = true
            this.gridApi = params.api;

            this.gridApi.setDatasource({
                rowCount: undefined,
                getRows: (params) => {
                    this.loaders.push(this.$loading.show({
                        'background-color': '#000000',
                        'color': '#FFFFFF'
                    }));

                    this.parameters = params;
                    this.paginationParams.page = params.endRow / this.numberItems;

                    this.launchRequest((response) => {
                        setTimeout(() => {
                            params.successCallback(response.data.data, response.data.totalRecords);

                            this.aggridCssProps = {
                                height: 50 + (70 * this.gridApi.getDisplayedRowCount()) + 'px'
                            };

                            if (this.loaders.length > 0) {
                                for (let loader of this.loaders) {
                                    loader.hide();
                                }
                                this.loaders = [];
                            }
                        }, 100);
                    });
                }
            });

            this.loadAggridArrangement();
        },
        onRowClicked: function(row) {
            if (($(row.event.target).hasClass('ag-cell') || $(row.event.target).is('span') || $(row.event.target).is('div')) && !($(row.event.target).hasClass('badge-messages'))) {
                that.$emit("rowClicked", row.data)
            }
        },
        getContextMenuItems(params) {
            let that = this;
            return [{
                name: 'Exporter en Excel',
                action: function () {
                    that.launchRequest((response) => {
                        that.getCSVFile(response.data.data);
                    }, true);
                },
                icon: '<span class="ag-icon ag-icon-excel" />',
            }];
        },
        launchRequest(callback, exportExcel = false) {
            let paginationParams = this.paginationParams;

            // Gestion de l'export Excel
            if (exportExcel) {
                paginationParams.page = 1;
                paginationParams.numberItems = 10000000;
            }

            const columnsSort = this.gridMergedOptions.columnApi.getColumnState().filter(column => column.sort !== null).sort((a, b) => a.sortIndex < b.sortIndex ? -1 : 1);

            let sortObject = {};

            for (let columnSort of columnsSort) {
                sortObject[columnSort.colId] = columnSort.sort;
            }

            if (this.aggridApiParams === null) this.aggridApiParams = {};
            this.aggridApiParams = Object.assign(this.aggridApiParams, {sort: JSON.stringify(sortObject)});

            // Préparation du payload
            let payload = JSON.parse(JSON.stringify(this.paginationParams));

            let newApiParam = {};
            for (let apiParamKey in this.aggridApiParams) {
                if (this.aggridApiParams[apiParamKey] != null) {
                    newApiParam[apiParamKey] = this.aggridApiParams[apiParamKey]
                }
            }

            if (Object.keys(newApiParam).length !== 0) {
                payload = Object.assign(payload, newApiParam);
            }

            const params = new URLSearchParams(payload);

            let apiUrl = this.apiRoute + '?' + params;

            axios.get(apiUrl, {
                toastError: true
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error', error)
            });
        },
        reload() {
            this.gridApi.refreshInfiniteCache();
        },
        onColumnVisible() {
            that.saveAggridArrangement();
        },
        onSortChanged() {
            that.saveAggridSort();
        },
        onColumnResized() {
            that.saveAggridArrangement();
        },
        getCSVFile(data) {
            let csvColumns = this.gridMergedOptions.columnApi.getColumnState().map(column => {
                let columnDetail = this.gridMergedOptions.columnApi.getColumn(column.colId);

                return {
                    id: columnDetail.colDef.cellRendererParams ? columnDetail.colDef.cellRendererParams.field : columnDetail.colDef.field,
                    name: columnDetail.colDef.headerName,
                    mode: columnDetail.colDef.cellRendererParams?.mode,
                    callback: columnDetail.colDef.cellRenderer
                }
            });

            let rows = [];

            let line = csvColumns.map(csvColumn => csvColumn.name);
            rows.push(line);

            for (let currentData of data) {
                line = csvColumns.map(csvColumn => {
                    let cell = '';
                    let value = csvColumn.id != null ? csvColumn.id.split('.').reduce((p,c)=>p&&p[c]||null, currentData) : null;

                    if (typeof csvColumn.callback === 'function') {
                        let span = document.createElement('span');
                        span.innerHTML = csvColumn.callback({
                            data: currentData
                        }).replace(/<br\s*\/?>/gi, ' ');
                        cell = (span.textContent || span.innerText).replace(/(\r\n|\n|\r)/gm, ' ');
                    } else {
                       switch (csvColumn.mode) {
                            case 'boolean':
                                cell = value ? 'Oui' : 'Non';
                            break;
                            case 'status':
                                cell = this.$store.getters.getAffairStatusList[value]?.label;
                            break;
                            case 'customerStatus':
                                cell = this.$store.getters.getAffairStatusList[value].customerLabel;
                            break;
                            default:
                                cell = value
                            break;
                        }
                    }

                    if (cell != null) cell = cell.replace(/\s\s+/g, ' ').trim();

                    return cell;
                });
                rows.push(line);
            }

            let csvContent = rows.map(e => e.join(";")).join("\n");

            this.exportCSV(csvContent, 'export_excel');
        },
        saveAggridArrangement() {
            // On récupère l'ensemble des colonnes
            const Column_Defs = this.gridApi.getColumnDefs();
            let columns = Column_Defs.map(column => column.field).join('_');

            // On génère une clé unique à partir de la route et du tableau
            let saveId = this.getUniqueSaveId(document.location.pathname + columns);
            // On enregistre dans le local storage les préférences d'affichage
            let aggridSave = JSON.parse(localStorage.getItem('aggridSave')) || {};
            aggridSave[saveId] = Column_Defs;
            localStorage.setItem('aggridSave', JSON.stringify(aggridSave));
        },
        saveAggridSort() {
            // On récupère l'ensemble des colonnes
            const Column_Defs = this.gridApi.getColumnDefs();
            let columns = Column_Defs.map(column => column.field).join('_');

            let sortSaveId = this.getUniqueSaveId(document.location.pathname + columns)
            let aggridSortSave = JSON.parse(localStorage.getItem('aggridSortSave')) || {};

            if (aggridSortSave[sortSaveId] == null) aggridSortSave[sortSaveId] = {}

            this.gridMergedOptions.columnApi.getColumnState().map(column => {
                if (column.sort !== null) {
                    if (!Object.keys(aggridSortSave[sortSaveId]).includes(column.colId)) aggridSortSave[sortSaveId] = {};
                    aggridSortSave[sortSaveId][column.colId] = {
                        sort: column.sort,
                        sorIndex: column.sortIndex
                    }
                } else {
                    delete aggridSortSave[sortSaveId]?.[column.colId]
                }
            });

            localStorage.setItem('aggridSortSave', JSON.stringify(aggridSortSave));
        },
        loadAggridArrangement() {
            // On récupère l'ensemble des colonnes
            let columns = this.gridApi.getColumnDefs().map(column => column.field).join('_');

            // On récupère la clé unique à partir de la route et du tableau
            let saveId = this.getUniqueSaveId(document.location.pathname + columns);
            let aggridSave = JSON.parse(localStorage.getItem('aggridSave') || null) || {};

            let sortSaveId = this.getUniqueSaveId(document.location.pathname + columns);
            let aggridSortSave = JSON.parse(localStorage.getItem('aggridSortSave') || null) || {};

            let columnSortSaved = aggridSortSave?.[sortSaveId] ? Object.keys(aggridSortSave?.[sortSaveId])?.[0] : null;

            // Si on a une sauvegarde, on l'applique
            if (aggridSave[saveId]) {
                let currentAggridArrangement = this.gridApi.getColumnDefs();
                let keyCurrentAggridArrangement = currentAggridArrangement.map(column => {
                    return column.field ?? (typeof column.cellRenderer === 'string' ? column.cellRenderer : column.headerName)
                }).join('_');

                let keySaveAggridArrangement = aggridSave[saveId].map(column => {
                    return column.field ?? (typeof column.cellRenderer === 'string' ? column.cellRenderer : column.headerName)
                }).join('_');

                // Si les clés sont identiques, c'est que côté développement on a pas ajouté/modifié les colonnes. Dans le cas contraire on ne peut pas recharger
                if (keyCurrentAggridArrangement === keySaveAggridArrangement) {
                    this.gridApi.setColumnDefs(aggridSave[saveId]);
                }
            }

            let columnDefs = this.gridApi.getColumnDefs();
            columnDefs.forEach(columnDef => {
                columnDef.hasOwnProperty('cellRendererFunctionName') && this.cellRendererFunctions.has(columnDef.cellRendererFunctionName) && (columnDef.cellRenderer = this.cellRendererFunctions.get(columnDef.cellRendererFunctionName));
            });
            columnDefs.forEach(columnDef => {
                columnDef.hasOwnProperty('valueFormatterFunctionName') && this.valueFormatterFunctions.has(columnDef.valueFormatterFunctionName) && (columnDef.valueFormatter = this.valueFormatterFunctions.get(columnDef.valueFormatterFunctionName));
            });

            this.gridApi.setColumnDefs(columnDefs);

            this.gridMergedOptions.columnApi.getColumnState().forEach(column => {
                if (columnSortSaved && column.colId === columnSortSaved) {
                    this.gridMergedOptions.columnApi.applyColumnState({
                        state: [
                            {
                                colId: columnSortSaved,
                                sort: aggridSortSave[sortSaveId][columnSortSaved]?.sort,
                                sortIndex: aggridSortSave[sortSaveId][columnSortSaved]?.sortIndex,
                            }
                        ]
                    })
                }
            });
        },
        getUniqueSaveId(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash  = ((hash << 5) - hash) + str.charCodeAt(i);
                hash |= 0;
            }
            return `save_${Math.abs(hash)}`;
        }
    }
  }
</script>
